jQuery(document).ready(function($){
    $.fn.editable.defaults.mode = 'inline';

    var $customersTable = $('#customersTable');

    $('.slim-500').slimScroll({
        height: '500px'
    });

    var $packageTypeSelect = $('#packageTypeSelect');
    var $serviceLevelSelect = $('#serviceLevelSelect');
    var $yearSelect = $('#yearSelect');

    $serviceLevelSelect.on('change', function(){
        $.ajax({
            "url" : '/options/package-type-options/' + $serviceLevelSelect.val(),
            "type": "GET",
            "data": {
                "service_level" : $serviceLevelSelect.val(),
            },
            "dataType" : "json",
            "success" : function(json) {
                $packageTypeSelect.find('option').remove();

                $packageTypeSelect.append($("<option />").val('').text(''));

                for (var k in json) {
                    $packageTypeSelect.append($("<option />").val(json[k].id).text(json[k].name));
                }
            }
        });
    });

    $('#exportAll').on('click', function(e){
        e.preventDefault();
        if ($packageTypeSelect.val() !== '' && $serviceLevelSelect !== '' && $yearSelect !== '') {
            $('[name="package_type_id"]').val($packageTypeSelect.val());
            $('[name="service_level_id"]').val($serviceLevelSelect.val());
            $('[name="year"]').val($yearSelect.val());

            $(this).closest('form').submit();
        }
    });

    // $.ajax({
    //     "url" : '/options/get-package-type-options',
    //     "type": "POST",
    //     "data": {
    //         "service_level" : $serviceLevelSelect.val(),
    //     },
    //     "dataType" : "json",
    //     "success" : function(json) {
    //         $packageTypeSelect.find('option').remove();

    //         $packageTypeSelect.append($("<option />").val('').text(''));

    //         $.each(json, function(i, v) {
    //             $packageTypeSelect.append($("<option />").val(i).text(v));
    //         });
    //     }
    // });
});